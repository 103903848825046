import ChartSection from "../components/sections/ChartSection"
import Header from "../components/sections/Header"
import ListSection from "../components/sections/ListSection"

function TopScreen() {

	document.title = "精算機マネージャー"



	return (

		<div className="h-screen   flex flex-col">

			<Header />

			<main className="mx-auto px-4 w-full lg:w-[1024px] lg:px-0   md:overflow-y-hidden">

				<div className="flex flex-wrap gap-y-8  w-full h-full">

					<div className="pt-12 md:pb-8   w-full md:w-2/3 md:pr-2   md:h-full md:overflow-y-scroll   md:scrollbar-styled">

						<ChartSection />
					</div>

					<div className="md:pt-12 pb-8   w-full md:w-1/3 md:pl-2   md:h-full md:overflow-y-scroll   md:scrollbar-styled md:scrollbar-left">

						<ListSection />
					</div>
				</div>
			</main>
		</div>
	)
}

export default TopScreen