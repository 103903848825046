import { NavLink } from "react-router-dom"

function Header() {

	return (

		<header className="bg-turquoise">

			<div className="mx-auto py-3 px-4   lg:w-[1024px] lg:px-0">

				<NavLink to="/" className="text-3xl text-white font-bold">精算機マネージャー</NavLink>
			</div>
		</header>
	)
}

export default Header