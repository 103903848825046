import dayjs from "dayjs"
import PaymentService from "../../../utils/PaymentService"
import { useEffect, useState } from "react"
import Payment from "../../../entities/Payment"
import LoadingIcon from "../others/LoadingIcon"
import { Unsubscribe } from "firebase/database"
import { NavLink } from "react-router-dom"
import { FaPlus } from "react-icons/fa6"

function ListSection() {

	// 読み取るpaymentデータ
	const [payments, setPayments] = useState<Payment[] | null>(null)
	const [isLoaded, setIsLoaded] = useState(false)

	// 読み取るPaymentの最大件数
	const [limit, setLimit] = useState(10)

	// 追加読み込みを完了したかどうか
	const [isLoadedMore, setIsLoadedMore] = useState(false)



	// レンダリング直後、paymentデータのリッスンを開始
	useEffect(() => {

		// リスナーのデタッチに使うunsubscribeメソッドを格納するための変数
		let unsubscribe: Unsubscribe | undefined

		(async () => {

			// setIsLoaded(false)

			// リスナーをセットし、最新のlimit件のPaymentを読み取り続ける
			unsubscribe = await PaymentService.onRecentlyPaymentsChanged(limit, payments => {

				setPayments(payments)
				setIsLoaded(true)
				setIsLoadedMore(true)

			}, (error) => {

				setIsLoaded(true)
			})

		})()

		// コンポーネントのクリーンアップ時に、リスナーをデタッチ
		return () => {
			if (unsubscribe) unsubscribe()
		}

		// eslint-disable-next-line
	}, [limit])



	return (

		<div className="bg-white p-4 rounded-xl">

			<div className="flex justify-between items-center   mt-[-0.25rem]">

				<p className="font-bold text-xl">最近の精算</p>

				<NavLink to="/add" className="mr-[-0.75rem]   p-3 rounded-full   text-turquoise hover:bg-turquoise/10 transition">
					<FaPlus />
				</NavLink>
			</div>


			<div className="mt-4">

				{!isLoaded &&
					<LoadingIcon center py />
				}

				{isLoaded && !payments &&
					<p className="text-slate-500">Error.</p>
				}

				{isLoaded && payments && payments.length === 0 &&
					<p className="text-slate-500">No data.</p>
				}



				{isLoaded && payments && payments.length !== 0 &&

					<div>
						<div className="divide-y divide-slate-200">

							{payments.map((payment, index) => (

								<div key={index} className="py-2   flex justify-between gap-4  first:pt-0 last:pb-0">

									<p className="text-slate-500 font-mono   overflow-hidden text-ellipsis whitespace-nowrap">

										<span>{dayjs(payment.timestamp).format("YYYY/MM/DD")}</span>
										<span className="ml-3">{dayjs(payment.timestamp).format("HH:mm")}</span>
									</p>

									<p className="whitespace-nowrap">{payment.amount}円</p>
								</div>
							))}
						</div>



						{payments.length === limit &&

							<button
								onClick={() => {
									setIsLoadedMore(false)
									setLimit(value => value + 10)
								}}
								className="block mt-3 mb-[-0.5rem] mx-auto    py-1 px-4 rounded-full   text-turquoise hover:bg-turquoise/10 transiton"
							>
								もっと読み込む
							</button>
						}

						{!isLoadedMore &&
							<LoadingIcon center className="mt-4" />
						}
					</div>
				}
			</div>
		</div>
	)
}

export default ListSection