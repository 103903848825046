import { NavLink } from "react-router-dom"
import Header from "../components/sections/Header"

function NotFoundScreen() {

	document.title = "Not Found - 精算機マネージャー"

	return (

		<div>

			<Header />

			<main className="mx-auto px-4 lg:w-[1024px] lg:px-0">

				<div className="mt-16 text-center">

					<h1 className="text-4xl font-bold">Page not found</h1>
					<p className="mt-2 text-slate-500">指定されたページが見つかりませんでした</p>

					<NavLink to="/" className="block w-fit mx-auto mt-4   py-1 px-4 rounded-full   text-turquoise hover:bg-turquoise/10 transition">トップへ戻る</NavLink>
				</div>
			</main>
		</div>
	)
}

export default NotFoundScreen