import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

interface Props {
	unit: "year" | "month" | "day"
	onSelect: (value: "year" | "month" | "day") => void
}

function UnitSelector(props: Props) {



	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}



	return (

		<div>

			<div className="hidden sm:block   flex">

				<button
					onClick={() => props.onSelect("year")}
					className={`py-1 px-4 text-sm   border border-slate-200 rounded-l-lg   hover:bg-slate-100   ${props.unit === "year" && "bg-slate-100"}`}
				>
					年
				</button>

				<button
					onClick={() => props.onSelect("month")}
					className={`py-1 px-4 text-sm   border-y border-slate-200   hover:bg-slate-100   ${props.unit === "month" && "bg-slate-100"}`}
				>
					月
				</button>

				<button
					onClick={() => props.onSelect("day")}
					className={`py-1 px-4 text-sm   border border-slate-200 rounded-r-lg   hover:bg-slate-100   ${props.unit === "day" && "bg-slate-100"}`}
				>
					日
				</button>
			</div>



			<div className="sm:hidden">

				<button
					aria-controls={open ? 'basic-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
					className='border py-1 px-2 rounded-lg   hover:bg-slate-100 transition'
				>
					{props.unit === "year" &&
						<p>年</p>
					}

					{props.unit === "month" &&
						<p>月</p>
					}

					{props.unit === "day" &&
						<p>日</p>
					}
				</button>

				<Menu
					id="basic-menu"
					open={open}
					onClose={handleClose}
					anchorEl={anchorEl}
				>
					<MenuItem onClick={() => props.onSelect("year")}>年</MenuItem>
					<MenuItem onClick={() => props.onSelect("month")}>月</MenuItem>
					<MenuItem onClick={() => props.onSelect("day")}>日</MenuItem>
				</Menu>

			</div>

		</div>
	)
}

export default UnitSelector