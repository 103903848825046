import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import BarChart from "../others/BarChart"
import { Unsubscribe } from "firebase/database"
import PaymentService from "../../../utils/PaymentService"
import Payment from "../../../entities/Payment"
import LoadingIcon from "../others/LoadingIcon"
import UnitSelector from "../others/UnitSelector"

function ChartSection() {

	// 表示単位 年/月/日
	const [unit, setUnit] = useState<"year" | "month" | "day">("month")

	// 基準値からの差
	const [offset, setOffset] = useState(0)



	// 表示単位が切り替わったら、offsetを0にする
	useEffect(() => {

		setOffset(0)
	}, [unit])



	// チャートにセットするdata
	const [payments, setPayments] = useState<Payment[] | null>(null)
	const [isLoaded, setIsLoaded] = useState(false)



	// unitやoffsetが変わるたびにpaymentsのリッスンを開始
	useEffect(() => {

		// リスナーのデタッチに使うunsubscribeメソッドを格納するための変数
		let unsubscribe: Unsubscribe | undefined

		(async () => {

			setIsLoaded(false)

			unsubscribe = await PaymentService.onPaymentsWithinPeriodChanged(unit, offset, payments => {

				// paymentsがnullなら失敗扱い
				if (!payments) {
					setIsLoaded(true)
					return
				}

				// State更新
				setPayments(payments)
				setIsLoaded(true)

			}, (error) => {

				setIsLoaded(true)
			})

		})()

		// コンポーネントのクリーンアップ時に、リスナーをデタッチ
		return () => {

			if (unsubscribe) unsubscribe()
		}

		// eslint-disable-next-line
	}, [offset, unit])



	return (

		<div className="bg-white p-4 rounded-xl">

			<div className="flex gap-y-1 items-center   sm:mt-[-0.25rem]">

				<div className="w-full   sm:w-1/3">

					<div className="hidden sm:block">
						{unit === "year" &&
							<p className="font-bold text-xl">{dayjs().add(offset, 'year').format("YYYY年")}</p>
						}

						{unit === "month" &&
							<p className="font-bold text-xl">{dayjs().add(offset, 'month').format("YYYY年M月")}</p>
						}

						{unit === "day" &&
							<p className="font-bold text-xl">{dayjs().add(offset, 'day').format("YYYY年M月D日")}</p>
						}
					</div>

					<div className="sm:hidden">
						{unit === "year" &&
							<p className="font-bold text-xl">{dayjs().add(offset, 'year').format("YYYY年")}</p>
						}

						{unit === "month" &&
							<p className="font-bold text-xl">{dayjs().add(offset, 'month').format("YYYY年M月")}</p>
						}

						{unit === "day" &&
							<p className="font-bold text-xl">{dayjs().add(offset, 'day').format("M月D日")}</p>
						}
					</div>
				</div>



				<div className="w-fit   sm:flex sm:w-1/3 sm:justify-center">

					<UnitSelector unit={unit} onSelect={(value) => setUnit(value)}/>
				</div>



				<div className="w-fit ml-2 sm:ml-0   flex justify-end    sm:w-1/3">

					<div className="flex items-center gap-1">

						<button onClick={() => setOffset(value => value - 1)} className="p-1 rounded-full   hover:bg-slate-100 transition">
							<MdKeyboardArrowLeft className="text-3xl text-zinc-500" />
						</button>

						<button onClick={() => setOffset(value => value + 1)} className="p-1 rounded-full   hover:bg-slate-100 transition   mr-[-0.25rem]">
							<MdKeyboardArrowRight className="text-3xl text-zinc-500" />
						</button>
					</div>
				</div>
			</div>
			


			<div>

				{!isLoaded &&
					<LoadingIcon center py />
				}

				{isLoaded && payments === null &&
					<p>Error</p>
				}

				{isLoaded && payments !== null &&

					<div>

						<div className="mt-3 flex gap-2">
							<p className="text-slate-500">合計</p>
							<p className="text-slate-500 font-bold-">{PaymentService.toTotalAmount(payments)}円</p>
						</div>

						<BarChart
							className="mt-2"
							payments={payments}
							offset={offset}
							unit={unit}
						/>
					</div>
				}
			</div>

		</div>
	)
}

export default ChartSection