import { BrowserRouter, Route, Routes } from "react-router-dom"
import TopScreen from "./views/screens/TopScreen"
import NotFoundScreen from "./views/screens/NotFoundScreen"
import AddScreen from "./views/screens/AddScreen"

function App() {

	return (

		<BrowserRouter>

			<Routes>

				<Route path="/" element={<TopScreen />} />
				<Route path="/add" element={<TopScreen />} />
				<Route path="*" element={<NotFoundScreen />} />
			</Routes>

			<Routes>
				<Route path="/add" element={<AddScreen />} />
				<Route path="*" element={<></>} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
