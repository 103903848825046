import { ref, getDatabase, push, orderByChild, query, limitToLast, IteratedDataSnapshot, onValue, startAt, endBefore, Unsubscribe } from "firebase/database"
import Payment from "../entities/Payment"
import { db } from "./firebase"
import dayjs from "dayjs"

class PaymentService {



	static toTotalAmount(from: Payment[]): number {

		const payments = from

		let total = 0

		payments.forEach(payment => {

			total += payment.amount
		})

		return total
	}



	static toPayment(from: IteratedDataSnapshot): Payment {

		const iteratedDataSnapshot = from

		// IDとプロパティの値を取り出し
		const id = iteratedDataSnapshot.key
		const amount = iteratedDataSnapshot.child("amount").val()
		const timestamp = iteratedDataSnapshot.child("timestamp").val()

		// Paymentオブジェクトを生成
		const payment: Payment = {
			id: id,
			amount: amount,
			timestamp: new Date(timestamp),
		}

		return payment
	}



	static async onRecentlyPaymentsChanged(
		limit: number,
		callback: (payments: Payment[] | null) => unknown,
		cancelCallback: (error: Error) => unknown,
	): Promise<Unsubscribe> {

		// クエリを用意
		// timestampで昇順に並べ替え、最後の10件を取得
		const q = query(
			ref(db, 'payments'),
			orderByChild('timestamp'),
			limitToLast(limit)
		)

		// payments以下が変更されるたびに、コードを実行
		return onValue(q, (dataSnapshot) => {

			// データが存在しなかった
			if (!dataSnapshot.exists()) {
				// Do nothing
			}

			// データが存在した
			console.log(`SUCCESS! Read ${dataSnapshot.size} payments.`)

			// データをPayment型の配列に変換
			// Payment型の配列を用意
			let payments: Payment[] = []

			// dataSnapshotからデータ一件一件を取り出し、Payment型に変換していく
			dataSnapshot.forEach(iteratedDataSnapshot => {

				const payment = PaymentService.toPayment(iteratedDataSnapshot)

				// 配列paymentsに追加
				payments.push(payment)
			})

			// 配列の順序を反転させ、降順にする
			const reversedPayments = payments.reverse()

			callback(reversedPayments)

		}, (error) => {

			console.error(`FAIL! Error to listen payments. ${error}`)
			cancelCallback(error)
		})
	}



	static async onPaymentsWithinPeriodChanged(
		unit: "year" | "month" | "day",
		offset: number,
		callback: (payments: Payment[] | null) => unknown,
		cancelCallback: (error: Error) => unknown,
	): Promise<Unsubscribe> {

		// unitとoffsetを元に、start時Timestampとend時Timestampを作成
		let start = ""
		let end = ""

		if (unit === "year") {
			start = dayjs().add(offset, 'year').format('YYYY')
			end = dayjs().add(offset + 1, 'year').format('YYYY')
		}

		if (unit === "month") {
			start = dayjs().add(offset, 'month').format('YYYY-MM')
			end = dayjs().add(offset + 1, 'month').format('YYYY-MM')
		}

		if (unit === "day") {
			start = dayjs().add(offset, 'day').format('YYYY-MM-DD')
			end = dayjs().add(offset + 1, 'day').format('YYYY-MM-DD')
		}

		// クエリを用意
		const q = query(
			ref(db, 'payments'),
			orderByChild('timestamp'),
			startAt(start),
			endBefore(end)
		)

		// payments以下が変更されるたびに、コードを実行
		return onValue(q, (dataSnapshot) => {

			// データが存在しなかった
			if (!dataSnapshot.exists()) {
				// Do nothing
			}

			// データが存在した
			console.log(`SUCCESS! Read ${dataSnapshot.size} payments.`)

			// データをPayment型の配列に変換
			// Payment型の配列を用意
			let payments: Payment[] = []

			// dataSnapshotからデータ一件一件を取り出し、Payment型に変換していく
			dataSnapshot.forEach(iteratedDataSnapshot => {

				const payment = PaymentService.toPayment(iteratedDataSnapshot)

				// 配列paymentsに追加
				payments.push(payment)
			})

			callback(payments)

		}, (error) => {

			console.error(`FAIL! Error to listen payments. ${error}`)
			cancelCallback(error)
		})
	}



	static async createPayment(amount: number, timestamp: Date): Promise<string | null> {

		// 8桁までの数値でなければ失敗とする
		if (amount > 99999999) return null

		// Realtime Databaseへの参照を取得
		const db = getDatabase()

		// Date型の日時を文字列に変換
		const timestampStr = dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')

		try {

			// データ追加
			const newPaymentRef = push(ref(db, 'payments/'), {
				amount: amount,
				timestamp: timestampStr
			})

			// 成功した
			console.log("SUCCESS! Add a payment.")

			// 新しいデータのkeyを返す
			return newPaymentRef.key

		} catch (error) {

			// 失敗した
			console.log(`FAIL! Error adding a payment. ${error}`)
			return null
		}
	}
}

export default PaymentService