import ReactLoading from "react-loading"

interface Props {
	center?: boolean
	py?: boolean

	className?: string
}

function LoadingIcon(props: Props) {

	return (

		<div className={props.className}>

			<div className={`${props.center && "w-full flex justify-center"} ${props.py && "py-4"}`}>

				<ReactLoading
					type="spin"
					color="#94A3B8"
					height="20px"
					width="20px"
				/>
			</div>
		</div>
	)
}

export default LoadingIcon