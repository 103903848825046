import { ThemeProvider } from "@emotion/react"
import { createTheme } from "@mui/material"
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useState } from "react"

interface Props {
	date: Date
	onSelect: (value: Date) => void
}

function MyDatePicker(props: Props) {

	// Pickerを開いているかどうか
	const [isOpen, setIsOpen] = useState(false)



	// テーマ設定
	const theme = createTheme({
		palette: {
			mode: 'light',
			primary: {
				main: '#24A5EC',
				contrastText: "#fff"
			},
		},
	})



	return (

		<>

			<button type="button" onClick={() => setIsOpen(true)} className="py-1 px-4  font-mono bg-slate-100 rounded-md   hover:bg-slate-200 transition">
				{dayjs(props.date).format('YYYY/MM/DD')}
			</button>

			{isOpen &&

				<div className="fixed top-0 left-0 w-full h-full   flex justify-center items-center">

					<div onClick={() => setIsOpen(false)} className="w-full h-full   bg-black/30"></div>

					<div className="absolute">

						<ThemeProvider theme={theme}>
							<LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: "YYYY年 M月" }}>

								<StaticDatePicker
									value={dayjs(props.date)}
									onChange={(newValue) => props.onSelect(newValue!.toDate())}
									onAccept={() => setIsOpen(false)}
									slotProps={{
										toolbar: {
											hidden: true
										},
										actionBar: {
											actions: []
										}
									}}
								/>

							</LocalizationProvider>
						</ThemeProvider>
					</div>
				</div>
			}
		</>
	)
}

export default MyDatePicker