import { ThemeProvider } from "@emotion/react"
import { createTheme } from "@mui/material"
import { LocalizationProvider, MultiSectionDigitalClock } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import React from "react"
import { useState } from "react"

interface Props {
	date: Date
	onSelect: (value: Date) => void
}

function TimePicker(props: Props) {

	// Pickerを開いているかどうか
	const [isOpen, setIsOpen] = useState(false)



	// テーマ設定
	const theme = createTheme({
		palette: {
			mode: 'light',
			primary: {
				main: '#24A5EC',
				contrastText: "#fff"
			},
		},
	})



	return (

		<>

			<button type="button" onClick={() => setIsOpen(true)} className="py-1 px-4  font-mono bg-slate-100 rounded-md   hover:bg-slate-200 transition">
				{dayjs(props.date).format('HH:mm')}
			</button>

			{isOpen &&

				<div className="fixed top-0 left-0 w-full h-full   flex justify-center items-center">

					<div onClick={() => setIsOpen(false)} className="w-full h-full   bg-black/30"></div>

					<div className="absolute bg-white p-4">

						<ThemeProvider theme={theme}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>

								<MultiSectionDigitalClock
									value={dayjs(props.date)}
									onChange={(newValue) => props.onSelect(newValue!.toDate())}
									ampm={false}
									timeSteps={{ minutes: 1 }}
									sx={{
										'& ::-webkit-scrollbar': {
											display: "none"
										}
									}}
								/>

							</LocalizationProvider>
						</ThemeProvider>
					</div>
				</div>
			}
		</>
	)
}

export default TimePicker