import { useState } from "react"
import URLModal from "../components/others/URLModal"
import PaymentService from "../../utils/PaymentService"
import { useNavigate } from "react-router-dom"
import DatePicker from "../components/others/DatePicker"
import TimePicker from "../components/others/TimePicker"

function AddScreen() {

	document.title = "新しい精算 - 精算機マネージャー"

	// 入力内容
	const [amountStr, setAmountStr] = useState("")
	const [timestamp, setTimestamp] = useState(new Date())

	// 画面遷移用Hooks
	const navigate = useNavigate()



	async function add() {

		// amountを数値に変換
		const amountNum = parseInt(amountStr)

		// 数値への変換に失敗したら、アラートを表示して終了
		if (Number.isNaN(amountNum)) {
			alert("入力値が不正です！")
			return
		}

		// データ追加
		const result = await PaymentService.createPayment(amountNum, timestamp)

		// 失敗したらアラート表示
		if (!result) {
			alert("データの追加に失敗しました。")
			return
		}

		// 成功したら"/"に戻る
		navigate(-1)
	}



	return (

		<URLModal>

			<p className="text-2xl font-bold">新しい精算</p>



			<input
				value={amountStr}
				inputMode="numeric"
				onChange={(e) => setAmountStr(e.target.value)}
				placeholder="金額"
				autoFocus
				className="mt-4   w-full py-2   border-b border-slate-300 outline-none focus:border-turquoise   appearance-none   placeholder:text-slate-400   rounded-none"
			/>



			<div className="flex justify-between items-center gap-x-16  flex-wrap">

				<div className="mt-4 flex gap-4">
					<DatePicker date={timestamp} onSelect={value => setTimestamp(value)} />
					<TimePicker date={timestamp} onSelect={value => setTimestamp(value)} />
				</div>

				<div className="w-full flex justify-end sm:w-fit">

					<button
						className="mt-4 mr-[-1rem] mb-[-0.5rem] sm:mb-0   font-bold disabled:text-slate-400   py-1 px-4 rounded-full  text-turquoise enabled:hover:bg-turquoise/10 transition"
						disabled={!amountStr.match(/^[1-9]+[0-9]*/g) || amountStr.length > 8}
						onClick={add}
					>
						追加
					</button>
				</div>

			</div>
		</URLModal>
	)
}

export default AddScreen