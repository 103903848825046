import dayjs from "dayjs"
import Payment from "../entities/Payment"
import ChartRecord from "../entities/ChartRecord"
import objectSupport from "dayjs/plugin/objectSupport"

class ChartService {

	static toYearChartRecords(payments: Payment[], offset: number): ChartRecord[] {

		// 年チャートで表示する年
		const now = dayjs()
		const shiftedNow = now.add(offset, 'year')
		const currentYear = shiftedNow.year()

		// 年内の月数
		const monthCount = 12

		let chartRecords: ChartRecord[] = []

		for (let i = 1; i < monthCount + 1; i++) {

			// この月の合計売上額
			let AmountAtMonth = 0

			// 全てのpaymentを精査し、この日のamountの合計を取得していく
			payments.forEach(payment => {

				// paymentのtimestamp、この日、この日の次の日の3つのDate
				const timestamp = payment.timestamp
				const currentMonth = dayjs(`${currentYear}-${i}-01`).toDate()
				const nextMonth = dayjs(`${currentYear}-${i + 1}-01`).toDate()

				// 3つのDateを比較して、timestampが今日と明日の間かどうか判定
				if (timestamp >= currentMonth && timestamp < nextMonth) {
					AmountAtMonth += payment.amount
				}
			})

			// この日の売上額が取得できたら、新しい要素を作る
			const chartRecord: ChartRecord = {
				label: `${i}月`,
				value: AmountAtMonth
			}

			// 配列に要素を追加
			chartRecords.push(chartRecord)
		}

		return chartRecords
	}



	static toMonthChartRecords(payments: Payment[], offset: number): ChartRecord[] {

		// 月チャートで表示する月
		const now = dayjs()
		const shiftedNow = now.add(offset, 'month')
		const currentYear = shiftedNow.year()
		const currentMonth = shiftedNow.month() + 1

		// 今月の日数
		const dayCount = dayjs().daysInMonth()

		// Chartにセットするdata
		let chartRecords: ChartRecord[] = []

		for (let i = 1; i < dayCount + 1; i++) {

			// この日の合計売上額
			let amountAtDay = 0

			// 全てのpaymentを精査し、この日のamountの合計を取得していく
			payments.forEach(payment => {

				// paymentのtimestamp、この日、この日の次の日の3つのDate
				const timestamp = payment.timestamp
				const currentDay = dayjs(`${currentYear}-${currentMonth}-${i}`).toDate()
				const nextDay = dayjs(`${currentYear}-${currentMonth}-${i + 1}`).toDate()

				// 3つのDateを比較して、timestampが今日と明日の間かどうか判定
				if (timestamp >= currentDay && timestamp < nextDay) {
					amountAtDay += payment.amount
				}
			})

			// この日の売上額が取得できたら、新しい要素を作る
			const chartRecord: ChartRecord = {
				label: `${i}日`,
				value: amountAtDay
			}

			// 配列に要素を追加
			chartRecords.push(chartRecord)
		}

		return chartRecords
	}



	static toDayChartRecords(payments: Payment[], offset: number): ChartRecord[] {

		// 日チャートで表示する日
		const now = dayjs()
		const shiftedNow = now.add(offset, 'day')
		const currentYear = shiftedNow.year() // 2023
		const currentMonth = shiftedNow.month() + 1 // 1-12
		const currentDay = shiftedNow.date() // 1-31

		// 一日内の時間数
		const hourCount = 24

		// Chartにセットするdata
		let chartRecords: ChartRecord[] = []

		for (let i = 0; i < hourCount; i++) {

			// この一時間の合計売上額
			let amountAtHour = 0

			// 全てのpaymentを精査し、この一時間のamountの合計を取得していく
			payments.forEach(payment => {

				// paymentのtimestamp、この時間、次の時間の3つのDate
				const timestamp = payment.timestamp

				dayjs.extend(objectSupport)

				const currentHour = dayjs({
					year: currentYear,
					month: currentMonth - 1,
					day: currentDay,
					hour: i
				}).toDate()

				const nextHour = dayjs({
					year: currentYear,
					month: currentMonth - 1,
					day: currentDay,
					hour: i + 1
				}).toDate()

				// 3つのDateを比較して、timestampが今日と明日の間かどうか判定
				if (timestamp >= currentHour && timestamp < nextHour) {
					amountAtHour += payment.amount
				}
			})

			// この一時間の売上額が取得できたら、新しい要素を作る
			const chartRecord: ChartRecord = {
				label: `${i}時`,
				value: amountAtHour
			}

			// 配列に要素を追加
			chartRecords.push(chartRecord)
		}

		return chartRecords
	}

}

export default ChartService