import ChartService from "../../../utils/ChartService"
import ChartRecord from "../../../entities/ChartRecord"
import Payment from "../../../entities/Payment"

import { Bar } from 'react-chartjs-2';
import { Chart, ChartOptions, registerables } from "chart.js"

import AspectRatio from '@mui/joy/AspectRatio';
import { CssVarsProvider } from '@mui/joy/styles';

Chart.register(...registerables)



interface Props {
	payments: Payment[]

	unit: "year" | "month" | "day"
	offset: number

	className?: string
}



function BarChart(props: Props) {

	// paymentsをチャートにセットできる形式に変換
	let chartRecords: ChartRecord[] = []

	if (props.unit === "year") {
		chartRecords = ChartService.toYearChartRecords(props.payments, props.offset)
	}

	if (props.unit === "month") {
		chartRecords = ChartService.toMonthChartRecords(props.payments, props.offset)
	}

	if (props.unit === "day") {
		chartRecords = ChartService.toDayChartRecords(props.payments, props.offset)
	}



	// 棒グラフ用のoptions
	const options: ChartOptions<'bar'> = {
		plugins: {
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false
				}
			},
			y: {
				min: 0,
				suggestedMax: 10,
				ticks: {
					stepSize: 1
				}
			}
		}
	};



	return (

		<div className={props.className}>

			<CssVarsProvider>
				<AspectRatio variant="plain">

					<Bar
						options={options}
						data={{
							labels: chartRecords.map(chartRecord => chartRecord.label),
							datasets: [{
								data: chartRecords.map(chartRecord => chartRecord.value),
								backgroundColor: "#24A5EC"
							}]
						}}
					/>
				</AspectRatio>
			</CssVarsProvider>
		</div>
	)
}

export default BarChart